import React, { useEffect } from 'react';
import { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../../components/layout';
import Breadcrumbs from '../../components/breadcrumbs';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import { graphql } from 'gatsby';

import { eventTransform } from '../../utils/cloudinary';

const typeTitle = {
  'meals': 'Meals Distribution',
  'donations': 'Clothes Donations',
  'special': 'Special Event',
};

const EventImage = (photo, index) => {
  const photoURL = eventTransform(photo.secure_url);
  return (
    <SwiperSlide key={index}>
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img src={photoURL} alt={photo.context?.custom?.alt} />
    </SwiperSlide>
  );
};

const GallerySwiper = ({ photos }) => {
  return (
    <Swiper
      className="event-details-slider swiper-container"
      modules={[Pagination, A11y, Autoplay]}
      loop={true}
      initialSlide={0}
      speed={400}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      spaceBetween={0}
      pagination={{ type: 'bullets', clickable: true }}
    >
      {photos.map(EventImage)}
    </Swiper>
  );
};

const EventDetails = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0,0); // fix Gatsby Link retaining scroll position
  }, []);

  return (
    <Layout>
      <main id="main">
        <Breadcrumbs title={data.event.title} />

        <section id="event-details" className="event-details">
          <div className="container">

            <div className="row gy-4">

              <div className="col-lg-8">
                <GallerySwiper photos={data.event.photos} />
              </div>

              <div className="col-lg-4">
                <div className="event-info">
                  <h3>Event information</h3>
                  <ul>
                    <li><strong>Type</strong>: { typeTitle[data.event.type] }</li>
                    <li><strong>Location</strong>: { data.event.location }</li>
                    <li><strong>Date</strong>: { data.event.date }</li>
                  </ul>
                </div>
                <div className="event-description">
                  <h2>{ data.event.title }</h2>
                  <BlockContent blocks={data.event.description} className="description" />
                </div>
              </div>

            </div>

          </div>
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    event: sanityEvent(id: {eq: $id}) {
      type
      title
      slug
      location
      id
      description: _rawDescription
      date
      photos {
        secure_url,
        context {
          custom {
            alt
          }
        }
      }
    }
  }
`;

export default EventDetails;
